<script>

import { BaseTabs } from '@/components/base';
import MomSchoolFutureEvents from '@/components/MomSchool/MomSchoolFutureEvents.vue';
import MomSchoolArchiveEvents from '@/components/MomSchool/MomSchoolArchiveEvents.vue';

export default {
  name: 'MomSchool',
  components: { BaseTabs, MomSchoolFutureEvents, MomSchoolArchiveEvents },
  data() {
    return {
      tabs: [
        {
          title: 'Будущие',
          value: 'MomSchoolFutureEvents',
        },
        {
          title: 'Архивные',
          value: 'MomSchoolArchiveEvents',
        },
      ],
      activeTab: 'MomSchoolFutureEvents',
    };
  },
};
</script>

<template>
  <div class="w-100 py-4 h-screen overflow-auto">
    <b-container fluid="xl">
      <div class="mb-3">
        <div class="h3 mb-0">
          Школа мам
        </div>
      </div>
      <div class="w-100">
        <base-tabs
          v-model="activeTab"
          :items="tabs"
          class="mom-school-tabs"
        />
        <component :is="activeTab" />
      </div>
    </b-container>
  </div>
</template>

<style scoped lang="scss">
.mom-school-tabs {
  margin-bottom: 30px;
  :deep(.crm-tab-wrapper) {
    flex-grow: 1;
    .crm-tab {
      width: 100%;
    }
  }
}
</style>
