<script>
import { momSchoolService } from '@/services';
import AsyncList from '@/components/AsyncList.vue';
import MomsSchoolEventsList from '@/components/MomSchool/MomsSchoolEventsList.vue';
import Bus from '@/eventBus';

export default {
  name: 'MomSchoolFutureEvents',
  components: { MomsSchoolEventsList, AsyncList },
  data() {
    return {
      eventsList: [],
      isLoading: false,
      isAllItemsLoaded: false,
    };
  },
  created() {
    this.fetchFutureEvents();
    Bus.$on('created', this.onCreated);
    Bus.$on('updated', this.onUpdated);
    Bus.$on('deleted', this.onDeleted);
  },
  beforeDestroy() {
    Bus.$off('created', this.onCreated);
    Bus.$off('updated', this.onUpdated);
    Bus.$off('deleted', this.onDeleted);
  },
  methods: {
    openAddModal() {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditEventModal',
      });
    },
    openEditModal(event) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'EditEventModal',
        props: { event },
      });
    },
    openDeleteModal(event) {
      this.$store.commit(this.$types.OPEN_MODAL, {
        name: 'DeleteEventModal',
        props: { event },
      });
    },
    async fetchFutureEvents(clear = false, limit = 5) {
      if (clear) {
        this.eventsList = [];
      }
      try {
        this.isLoading = true;
        const prevItemsCount = this.eventsList.length;
        const { data } = await momSchoolService.getEvents({
          skip: prevItemsCount,
          take: limit,
          statuses: ['Planned'],
          IsDeleted: false,
        });
        this.eventsList = [...this.eventsList, ...data.events];
        if (this.eventsList.length < prevItemsCount + 5) {
          this.isAllItemsLoaded = true;
        }
      } catch (error) {
        console.warn(error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadMoreItems() {
      if (!this.isLoading && !this.isAllItemsLoaded) {
        await this.fetchFutureEvents();
      }
    },
    async onCreated() {
      await this.fetchFutureEvents(true, this.eventsList.length + 1);
    },
    async onUpdated() {
      await this.fetchFutureEvents(true, this.eventsList.length);
    },
    async onDeleted() {
      await this.fetchFutureEvents(true, this.isAllItemsLoaded ? this.eventsList.length - 1 : this.eventsList.length);
    },
  },
};
</script>

<template>
  <div>
    <async-list
      :is-all-items-loaded="isAllItemsLoaded"
      without-loader
      class="events-list"
      @load-more-items="loadMoreItems"
    >
      <div class="flex justify-end">
        <b-button
          variant="primary"
          class="mb-6"
          @click="openAddModal"
        >
          <b-icon icon="plus" />
          <span>Добавить вебинар</span>
        </b-button>
      </div>
      <moms-school-events-list
        :events-list="eventsList"
        :is-loading="isLoading"
      >
        <template #default="{ event }">
          <div class="flex flex-col gap-2">
            <b-button
              variant="primary"
              title="Редактировать вебинар"
              @click="openEditModal(event)"
            >
              <b-icon icon="pencil" />
            </b-button>
            <b-button
              variant="danger"
              @click="openDeleteModal(event)"
            >
              <b-icon icon="trash" />
            </b-button>
          </div>
        </template>
      </moms-school-events-list>
    </async-list>
  </div>
</template>

<style scoped lang="scss">
.events-list {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
</style>
