<script>
import { format, parseISO } from '@evd3v/date-fns';

export default {
  name: 'MomsSchoolEventTemplate',
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  methods: { parseISO, format },
};
</script>

<template>
  <div class="p-4 bg-white rounded border flex gap-6">
    <div class="flex-grow-1">
      <p class="h5 mb-3">
        {{ event.name }}
      </p>
      <div class="flex gap-6 mb-3">
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Дата вебинара:
          </div>
          <div class="ml-2 text-break">
            {{ format(parseISO(event.startDate), 'dd.MM.yyyy') }}
          </div>
        </div>
        <div class="d-flex mb-1">
          <div class="font-weight-bold">
            Время вебинара:
          </div>
          <div class="ml-2 text-break">
            {{ format(parseISO(event.startDate), 'HH:mm') }}
          </div>
        </div>
      </div>
      <div class="d-flex mb-1">
        <div class="font-weight-bold">
          Записалось:
        </div>
        <div class="ml-2 text-break">
          {{ event.registrationsCount }}
        </div>
      </div>
    </div>
    <div
      v-if="$slots.default"
      class="flex-shrink-0"
    >
      <slot />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
