<script>
import MomsSchoolEventsList from '@/components/MomSchool/MomsSchoolEventsList.vue';
import AsyncList from '@/components/AsyncList.vue';
import { momSchoolService } from '@/services';

export default {
  name: 'MomSchoolArchiveEvents',
  components: { MomsSchoolEventsList, AsyncList },
  data() {
    return {
      eventsList: [],
      isLoading: false,
      isAllItemsLoaded: false,
    };
  },
  created() {
    this.fetchArchiveEvents();
  },
  methods: {
    async fetchArchiveEvents() {
      try {
        this.isLoading = true;
        const prevItemsCount = this.eventsList.length;
        const { data } = await momSchoolService.getEvents({
          skip: prevItemsCount,
          take: 10,
          statuses: ['OnAir', 'Finished'],
          IsDeleted: false,
        });
        this.eventsList = [...this.eventsList, ...data.events];
        if (this.eventsList.length < prevItemsCount + 10) {
          this.isAllItemsLoaded = true;
        }
      } catch (error) {
        console.warn(error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadMoreItems() {
      if (!this.isLoading && !this.isAllItemsLoaded) {
        await this.fetchArchiveEvents();
      }
    },
  },
};
</script>

<template>
  <async-list
    :is-all-items-loaded="isAllItemsLoaded"
    without-loader
    class="events-list"
    @load-more-items="loadMoreItems"
  >
    <moms-school-events-list
      :events-list="eventsList"
      :is-loading="isLoading"
    />
  </async-list>
</template>

<style scoped lang="scss">
.events-list {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
</style>
