<script>
import MomsSchoolEventTemplate from '@/components/MomSchool/MomsSchoolEventTemplate.vue';

export default {
  name: 'MomsSchoolEventsList',
  components: { MomsSchoolEventTemplate },
  emits: ['loadMoreItems'],
  props: {
    eventsList: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div>
    <moms-school-event-template
      v-for="event in eventsList"
      :key="event.id"
      :event="event"
      class="mb-2"
    >
      <slot :event="event" />
    </moms-school-event-template>
    <div v-if="isLoading">
      <b-col
        v-for="item in 5"
        :key="item"
        cols="12"
        class="mt-2 p-0 rounded"
      >
        <b-skeleton-img
          no-aspect
          height="148px"
        />
      </b-col>
    </div>
  </div>
</template>

<style scoped lang="scss">
.events-list {
  height: 100%;
  overflow: auto;
  box-sizing: border-box;
}
</style>
